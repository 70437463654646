import React from 'react';
import Helmet from 'react-helmet';
import Section from '@latitude/section';
import {
  BUTTON_STYLE,
  ALIGN,
  COLOR,
  BREAKPOINT
} from '@latitude/core/utils/constants';
import { Strong, Text } from '@latitude/text';
import { ImportantInformation } from '@latitude/important-information';
import { Accordion } from '@latitude/accordion';
import { StickyNavigation } from '@latitude/sticky-navigation';
import Hero from '../../components/Hero/Hero';
import Layout from '../../components/layout';
import './closure.scss';
import allDataJson from '../../data/pages/genoapay-closure.json';
import CalloutBox from '../../components/CalloutBox/CalloutBox';
import FrequentlyAskedQuestions from '../../components/ManageYourLoan/ManageYourLoan';
import Image from '../../components/Image/Image';
import { PageContext } from '@/context/PageContext';
import { getAccordionSidebarData } from '@/utils/helpers';
import HeroBanner from '@/components/lab-components/HeroBanner';

class GenoapayPage extends React.Component {
  static contextType = PageContext;
  constructor(props) {
    super(props);
    this.state = { selectedListFaq: 0 };
    this.selectItem = this.selectItem.bind(this);
    this.genoapayPageFaq = allDataJson.faq;
  }

  state = { isNavSticky: false };

  // Handle the change of the "Sticky Navigation" state
  handleStickyNavStateChange = isNavSticky =>
    this.setState(prevState => {
      if (prevState.isNavSticky !== isNavSticky)
        return { ...prevState, isNavSticky };
      return prevState;
    });

  selectItem(index) {
    this.setState({ selectedListFaq: index });
  }

  render() {
    return (
      <Layout>
        <main className="navigation-spacer genoapay-page">
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/genoapay/closure"
            />
            <title>Genoapay Closure| Gem by Latitude</title>
            <meta
              name="description"
              content="Genoapay lets you get what you want today and spread the payments out over 10 weekly instalments. T&amp;Cs apply."
            />
          </Helmet>

          {this.context?.updatedBody?.heroBannerData?.[0] ? (
            <HeroBanner {...this.context?.updatedBody?.heroBannerData[0]} />
          ) : (
            <Hero
              backgroundImage={require('../../images/genopay-decomission-banner.webp')}
              heading="Genoapay has closed"
              intro="Thanks for shopping with us. Genoapay is no longer available for customers or merchants. But don’t worry, Gem is still here for your life’s moments big and small."
              className="page-banner-lfs--bg"
              cta={
                <div className="row">
                  <div className="col-md-6 col-sm-12"></div>
                </div>
              }
              image=""
            />
          )}

          <StickyNavigation
            items={allDataJson.nav}
            isSticky={this.state.isNavSticky}
            onStickyNavStateChange={this.handleStickyNavStateChange}
            offsetElem="[data-sticky-navigation-offset]"
            {...this.context?.updatedBody?.inPageNavData?.[0]}
          />

          <Section
            id="what-is-genoapay"
            backgroundImage={require('./images/genoapay-confetti-background.webp')}
            className="bg-white"
          >
            <CalloutBox
              id="whats-happening"
              title="What's happening"
              image={
                <Image
                  src={require('./images/whats-happening.webp')}
                  title="Good shopping is in our DNA."
                  alt=""
                />
              }
              desc={
                <>
                  <div>
                    <p>
                      <ul>
                        <li>Genoapay is no longer available in New Zealand.</li>
                        <li>
                          We will close all customer accounts once any
                          outstanding balance has been paid.
                        </li>
                      </ul>
                    </p>
                  </div>
                </>
              }
              cta={
                <div className="row">
                  <div className="col-12 col-lg-6"></div>
                </div>
              }
            />
          </Section>

          <div
            id="faq"
            data-contentful={
              this.context?.updatedBody?.faqData?.[0]?.contentfulID
            }
            css={`
              && {
                background-color: #f8f8f8;
                padding-top: 10px;
                padding-bottom: 0;

                @media (min-width: ${BREAKPOINT.LG}) {
                  padding-top: 42px;
                  padding-bottom: 0;
                }
              }
            `}
          >
            <FrequentlyAskedQuestions
              heading={
                this.context?.updatedBody?.faqData?.[0]?.title ||
                'Want to know more about the Genoapay Closure?'
              }
            >
              <text class="text-start">
                {this.context?.updatedBody?.faqData?.[0]?.description ||
                  `As you may be aware, Genoapay closed on 11th April 2023. Thank
                you for being a customer, we’ve loved helping you shop for
                life’s moments big and small, but we had to make the difficult
                decision to stop offering this service. However, we are still
                here to support you and here is what you need to know:`}
                <br />
                <br />
              </text>
              <Accordion
                titleBgColor="grey-light"
                items={
                  this.context?.updatedBody?.faqData?.[0]?.data || [
                    ({
                      id: 'item-1',
                      title: 'How can I request a closure letter? ',
                      content: (
                        <div className="w-100 mt-4">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                You can request for a closure letter if you had
                                a Genoapay account with us. You will need to
                                raise a request for an account closure letter{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-2',
                      title:
                        'What happens to the personal information you collected from me?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12">
                              <Text>
                                We treat all customer information carefully and
                                you can view our{' '}
                                <a
                                  href="https://www.gemfinance.co.nz/privacy/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Privacy Policy here
                                </a>
                                .<br />
                                <Strong>Please Note:</Strong> We will manage any
                                requests as per Latitude privacy policy.
                                <br />
                                If you would like to learn more on what personal
                                information Genoapay holds you can raise a
                                request{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-3',
                      title:
                        'How can I view my purchase history, can I log in?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                Unfortunately, you can no longer log into the
                                customer portal as it is now closed. However,
                                you can raise a request to obtain previous plan
                                history data{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-4',
                      title: 'How do Returns and Refunds work?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                Returns and refunds will always be determined by
                                the returns policy of the individual retailer or
                                service provider you bought the item or service
                                from. You should be able to find out your
                                options by checking their returns policy on
                                their website or contacting them directly.
                                <br />
                                <br />
                                If you need to return something, you will have
                                to talk to the merchant directly. If you have
                                any concerns with merchant providing refund,
                                please raise a request{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-5',
                      title: 'Can I contact someone about my account?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                Even though the product is now closed you can
                                still get in touch with our team{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-6',
                      title: 'What will appear on my credit file?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                You can learn more about this{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/articles/15912186547345-Comprehensive-Credit-Reporting"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-7',
                      title:
                        'There is an error on my credit file that was reported by Genoapay, what should I do?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                If you believe Genoapay has reported something
                                in error regarding your credit history, you are
                                able to contact us{' '}
                                <a
                                  href="https://gemfinance.zendesk.com/hc/en-nz/requests/new?ticket_form_id=17429141012753"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-8',
                      title:
                        'How long is information retained on my Credit Report?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                Please see below table extracted from the Credit
                                Reporting Privacy Code 2020: <br />
                                <table
                                  class="tg table-bordered"
                                  border="1"
                                  cellspacing="0"
                                  cellpadding="5"
                                >
                                  <thead>
                                    <tr>
                                      <th class="tg-0lax">
                                        Types of Credit Information
                                      </th>
                                      <th class="tg-0lax">
                                        Maximum Reporting Period
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td class="tg-0lax">
                                        Lost, stolen or compromised
                                        identification documents
                                        information&nbsp;&nbsp;
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of report
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Credit application information
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of application
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Credit account information (other than
                                        repayment history information)
                                      </td>
                                      <td class="tg-0lax">
                                        2 years from date credit account closed
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Repayment history information
                                      </td>
                                      <td class="tg-0lax">
                                        2 years from month following due date of
                                        periodic payment
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Credit default information relating to
                                        debtor credit default
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of default
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Credit default information relating to
                                        guarantor credit default
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of notification of
                                        debtor’s default to guarantor
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Serious credit infringement information
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of action
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Credit non-compliance action information
                                      </td>
                                      <td class="tg-0lax">
                                        6 months from date of action
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Confirmed credit non-compliance action
                                        information
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of action
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">Judgments</td>
                                      <td class="tg-0lax">
                                        5 years from date of judgment
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">Single bankruptcy</td>
                                      <td class="tg-0lax">
                                        4 years from date of discharge from the
                                        no asset procedure&nbsp;&nbsp;
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Single entry to no asset procedure
                                      </td>
                                      <td class="tg-0lax">
                                        4 years from date of discharge from the
                                        no asset procedure&nbsp;&nbsp;
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Multiple No Asset Procedures (NAPs) or{' '}
                                        <br />
                                        Bankruptcy events (as provided in the{' '}
                                        <br />
                                        Insolvency Act 2006, section 449A)
                                      </td>
                                      <td class="tg-0lax">Indefinite</td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Debt repayment orders
                                      </td>
                                      <td class="tg-0lax">
                                        5 years from date of order
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">
                                        Previous enquiry record
                                      </td>
                                      <td class="tg-0lax">
                                        4 years from date of enquiry
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="tg-0lax">Credit score</td>
                                      <td class="tg-0lax">
                                        2 working days from date of creation
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-9',
                      title: 'How can I make a complaint?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                If you have a complaint, you can let us know{' '}
                                <a
                                  href="https://www.gemfinance.co.nz/complaints/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-10',
                      title: 'What does this mean for your other products?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                We continue to offer our other long-standing
                                products – View our product offerings{' '}
                                <a
                                  href="https://www.gemfinance.co.nz/"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                                .
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-11',
                      title:
                        'I think I’ve received a message about Genoapay in error. I don’t have an account.',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                If you do not have a Genoapay account with us
                                anymore, then you can disregard the email. The
                                email may have been sent to you because you
                                either had an account previously, or applied for
                                an account in the past. We apologise for the
                                error and inconvenience.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ]
                }
              />
            </FrequentlyAskedQuestions>
          </div>

          <div
            id="faq-merchant"
            data-contentful={
              this.context?.updatedBody?.faqData?.[1]?.contentfulID
            }
            css={`
              && {
                background-color: #f8f8f8;
                padding-top: 10px;
                padding-bottom: 0;

                @media (min-width: ${BREAKPOINT.LG}) {
                  padding-top: 42px;
                  padding-bottom: 0;
                }
              }
            `}
          >
            <FrequentlyAskedQuestions
              heading={
                this.context?.updatedBody?.faqData?.[1]?.title ||
                'Merchant FAQs'
              }
            >
              <Accordion
                titleBgColor="grey-light"
                items={
                  this.context?.updatedBody?.faqData?.[1]?.data || [
                    {
                      id: 'item-1',
                      title:
                        'How do I access previous transactions or reporting?',
                      content: (
                        <div className="w-100 mt-4">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                We are still here to assist you if you need any
                                support accessing old transaction information.
                                You can now raise a request to access any
                                previous purchase information or reporting{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-2',
                      title: 'Need help regarding a payment?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12">
                              <Text>
                                You can raise a concern regarding payment
                                queries{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-3',
                      title: 'Wish to raise a complaint?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                You can raise a concern or feedback through{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-4',
                      title:
                        'How do I remove the plug ins from my website? Where can I get support?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                To remove our plugin from your website, please
                                refer to the list below and select the relevant
                                link associated with the ecommerce platform:
                                <ul>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/bigcommerce#5-disable-latitudepaygenoapay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      BigCommerce
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/magento1/#removing-plugin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Magento 1
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/magento2/#removeuninstall-latitudepay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Magento 2
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/opencart2/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      OpenCart 2
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/opencart3/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      OpenCart 3
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/prestashop-1-6/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      PrestaShop 1.6
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/prestashop-1-7/#uninstallation"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      PrestaShop 1.7
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/shopify/#removeuninstall-latitudepay"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Shopify
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://resources.latitudefinancial.com/docs/latitude-pay/woocommerce/#removeuninstall-woocommerce-plugin"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      WooCommerce
                                    </a>
                                  </li>
                                </ul>
                                For more information, please visit our{' '}
                                <a
                                  href="https://resources.latitudefinancial.com/docs/latitude-pay/bigcommerce/#5-disable-latitudepaygenoapay"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Resource Toolkit
                                </a>
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    },
                    {
                      id: 'item-5',
                      title: 'Can’t see an answer to your problem?',
                      content: (
                        <div className="w-100">
                          <div className="row">
                            <div className="col-12 mt-1">
                              <Text>
                                You can raise a request through{' '}
                                <a
                                  href="https://latitudefs.zendesk.com/hc/en-au/requests/new?ticket_form_id=18364767918225"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  here
                                </a>{' '}
                                and a Latitude representative will be in contact
                                with you to assist.
                              </Text>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  ]
                }
              />
            </FrequentlyAskedQuestions>
          </div>

          <CalloutBox
            id="good-shopping"
            title="Good shopping is in our DNA."
            image={
              <Image
                src={require('./images/good-shopping.webp')}
                title="Good shopping is in our DNA."
                alt=""
              />
            }
            desc={
              <>
                When you've been around for over 100 years, you understand when
                your customers shopping needs are changing. That's why we know
                the time is right to close Genoapay. The good news is, we have
                other options like a Gem Visa that offers Interest Free
                <sup>*</sup> payment options to keep you covered.
              </>
            }
            cta={
              <div className="row">
                <div className="col-12"></div>
              </div>
            }
          />

          <ImportantInformation
            id="important-information"
            data={allDataJson.importantinfocontent}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            {...this.context?.updatedBody?.importantInfoData?.[0]}
          />
        </main>
      </Layout>
    );
  }
}

export default GenoapayPage;
